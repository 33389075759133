import React, { useEffect } from 'react';
import { Toast } from 'antd-mobile';

type TLoading = React.FC<{ duration?: number }> & {
  show: (content?: React.ReactNode) => void;
  hide: () => void;
};

const Loading: TLoading = (props?: { duration?: number }) => {
  useEffect(() => {
    Toast.show({ icon: 'loading', duration: props?.duration || 0 });
    return () => Toast.clear();
  }, []);

  return null;
};

Loading.show = (content = '') => {
  Toast.show({ icon: 'loading', content, duration: 0 });
};

Loading.hide = () => {
  Toast.clear();
};

export default Loading;
