import '@/utils/flexible';

import scout from '@qfe/scout';
import { history } from 'umi';
import scoutConfig from './scout.config';
import Pages from './enums/Pages';
import { isPC, redirectToCurCounrty } from './utils/utils';
import { setLocale, getLocale } from '@/locales';
import debugHook from '@/utils/debug';
// import './assets/sentry';

setLocale(getLocale(), false);

// 开发环境使用 vconsole
debugHook();

if (SWITCHES.scout) {
  scout.init(scoutConfig);
}

// redirectToCurCounrty();

export async function render(oldRender: () => void): Promise<void> {
  oldRender();
}

if (isPC() || window.location.pathname === Pages.Invoice) {
  (document.querySelector('[name="viewport"]') as Element).remove();
}

/**
 * 路由信息，只定义需要用到的，还有其他属性
 */
interface Route {
  /**
   * 路径
   */
  path: Pages | string;

  /**
   * 标题
   */
  title: string;
}

/**
 * 匹配到路由信息，只定义需要用到的，还有其他属性
 */
interface MatchedRoute {
  route: Route;
}

interface RouteChangeEvent {
  matchedRoutes: MatchedRoute[];
}

// 路由变更
export function onRouteChange(e: RouteChangeEvent): void {
  if (!e.matchedRoutes || !e.matchedRoutes.length) {
    return;
  }
  const { route } = e.matchedRoutes[0];

  if (route?.title) {
    document.title = route.title;
  }
  if (route.path) {
    (window.document.querySelector('html') as HTMLHtmlElement).dataset.path = route.path;
  }
}
