// @ts-nocheck
import { plugin } from './plugin';
import * as Plugin_0 from '/usr/src/app/src/app.tsx';
import * as Plugin_1 from '../plugin-locale/runtime.tsx';

  plugin.register({
    apply: Plugin_0,
    path: '/usr/src/app/src/app.tsx',
  });
  plugin.register({
    apply: Plugin_1,
    path: '../plugin-locale/runtime.tsx',
  });
