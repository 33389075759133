export default {
  '国家或地区': '國家或地區',
  '西班牙': '西班牙',
  '澳大利亚': '澳大利亞',
  '英国': '英國',
  '选择语言': '選擇語言',
  'ABN': 'ABN',
  'Download the Yourbox App': '下載Yourbox APP',
  '会话已超时，可返回上一页重新进入': '會話已超時，可返回上一頁重新進入',
  'Scan with mobile device to download the YOURBOX APP': '掃碼下載Yourbox App',
  'Global Trendsetter': '全球潮流引領者',
  'Quality Beyond Compare': '無與倫比的品質',
  'Value for Money': '物超所值',
  'Technology Innovation': '技術創新',
  'We consistently monitor global trends, ensuring our offerings remain avant-garde.': '我們持續關注全球趨勢，確保我們的產品保持前衛。',
  'We present you with the latest and most distinctive products, ensuring you remain at the vanguard of both fashion and innovation.': '我們為您提供最新、最獨特的產品，確保您始終處於時尚和創新的先鋒地位。',
  'Light Luxury Essence': '輕奢精華',
  'We ardently believe that high-caliber products imbue an inherent touch of light luxury into everyday life.': '我們深信，高品質的產品能為日常生活注入與生俱來的輕奢氣息。',
  'Each Yourbox product is meticulously designed to emanate an aura of opulence and sophistication.': '每件 Yourbox 產品都經過精心設計，散發出奢華和精緻的氣息。',
  'Affordable Elegance': '經濟實惠的優雅',
  'Our commitment extends beyond mere high quality; it encompasses accessibility for all.': '我們的承諾不僅僅是高品質； 它涵蓋了所有人的無障礙性。',
  'We meticulously uphold prices that are not just budget-friendly but afford you the opportunity to seamlessly embrace an upscale lifestyle.': '我們精心維護的價格不僅適合預算，而且為您提供無縫擁抱高檔生活方式的機會。',
  'FASTHORSE LME, SOCIEDAD LIMITADA': 'FASTHORSE LME, SOCIEDAD LIMITADA',
  'LAST MILE EXPRESS PTY LTD': 'LAST MILE EXPRESS PTY LTD',
  'LAST MILE EXPRESS LIMITED': 'LAST MILE EXPRESS LIMITED',
  'Choosing Yourbox means choosing global excellence, experiencing upscale technology products, and enjoying affordable elegance. Thank you for becoming a part of our brand!': '選擇Yourbox意味著選擇全球卓越，體驗高階科技產品，享受實惠的優雅。 感謝您成為我們品牌的一部分！',
  'or its affiliates Contact us at': '或其附屬公司與我們聯絡：',
  'Welcome to Yourbox': '歡迎來到Yourbox',
  'What sets us apart?': '我們有何不同之處？',
  'afforable price': '實惠的價格',
  'We take pride in our unique global selection of trending products while maintaining a more': '我們為我們獨特的全球流行產品選擇而感到自豪，同時保持更',
  'Tax Invoice': '稅務發票',
  'Invoice/Order Number': '發票/訂單號',
  'Delivery Date': '配送日期',
  'Delivery Time': '配送時間',
  'Supplied': '商品明細',
  'Line': '序號',
  'Description': '商品描述',
  'Quantity': '數量',
  'Price': '單價',
  'Amount': '總價',
  'Item subtotal': '商品總價',
  'Delivery fee': '配送費',
  'Coupons': '優惠金額',
  'Subtotal': '合計',
  'GST Included In Subtotal': '增值稅包含在合計中',
  'Indicates GST applicable items': '帶*為含稅商品',
  'Discount': '折扣金額',
  'Company Name': '公司名稱',
  'Store activities': '門店活動',
  'Jan': '一月',
  'Feb': '二月',
  'Mar': '三月',
  'Apr': '四月',
  'May': '五月',
  'Jun': '六月',
  'July': '七月',
  'Aug': '八月',
  'Sep': '九月',
  'Oct': '十月',
  'Nov': '十一月',
  'Dec': '十二月',
  'Step': '步驟',
  'Download our Yourbox App': '下載我們的Yourbox App',
  'Register an account': '註冊一個帳號',
  'Yourbox App scans code to pay': '使用Yourbox App掃碼',
  'Welcome to our store': 'Yourbox歡迎您',
  'Yourbox Pay': 'Yourbox Pay',
  'Pay with Yourbox': '使用Yourbox支付',
  'At Yourbox, we believe everyone deserves access to the latest electronic gadgets, coolest toys, and essential home appliances without breaking the bank. That\'s why we offer a curated selection of trendy products at incredibly affordable prices.': '在 Yourbox，我們相信每個人都應該在不花太多錢的情況下獲得最新的電子產品、最酷的玩具和基本的家用電器。 這就是為什麼我們以極其實惠的價格提供精選的時尚產品。',
  'Welcome to Yourbox Pay Later': '歡迎使用 Yourbox Pay Later',
  'Quality Guarantee': '品質保證',
  'Buy Now Pay Later': '先買後付',
  'Zero Interest rate': '零利率',
  'Affordable Prices': '付得起的價錢',
  'Our products are priced competitively, ensuring you get the best value for your money.': '我們的產品價格具有競爭力，確保您物有所值。',
  'Trendy Selection': '時尚精選',
  'Stay ahead of the curve with our constantly updated collection of electronics, toys, and home appliances.': '透過我們不斷更新的電子產品、玩具和家用電器系列保持領先地位。',
  'Zero Interest': '零利息',
  'Say goodbye to hidden fees and extra charges. With zero interest, you can shop with confidence knowing exactly what you\'ll pay.': '告別隱藏費用和額外費用。 由於零利息，您可以放心購物，確切地知道您將支付什麼費用。',
  'Shop with Confidence': '放心購物',
  'Don\'t let budget constraints hold you back from getting the products you desire.  Experience the convenience of buying now and paying later with Yourbox Pay Later.  Start browsing our collection now and enjoy the latest trends at unbeatable prices!': '不要讓預算限制阻礙您獲得所需的產品。 使用 Yourbox Pay Later 體驗先購買後付款的便利性。 立即開始瀏覽我們的產品，以無與倫比的價格享受最新趨勢！',
  '接受': '接受',
  '拒绝': '拒絕',
  'You have already be ${name} contact': '你已成為${name}在Yourbox的聯絡人',
  'You have already reject to be ${name} contact': '你已拒絕成為${name}在Yourbox的聯絡人',
  '${name} invites you to be his contact': '${name}邀請你成為他的聯絡人',
  '确定要接受么？': '確定要接受麼？',
  '确定要拒绝么？': '確定要拒絕麼？',
  'Submit successfully': '提交成功',
  'Your invoice request has been submitted': '您的發票申請已提交',
  'successfully.': '成功',
  'The invoice will be sent to your email': '發票將寄至您的電子郵件信箱',
  'within 7 working days.': '7個工作天內。',
  'Please wait patiently and check your': '請耐心等待並檢查您的',
  'email. Thank you!': '電子郵件。謝謝你！',
  'Issue an invoice': '開立發票',
  'Please fill in your invoice information accurately': '請準確填寫您的發票資訊',
  'and cannot be changed after confirmation': '確認後不可更改',
  'Invoice details': '發票明細',
  'Invoice title': '發票標題',
  'Please enter Invoice title': '請輸入發票標題',
  'Fill in the Invoice title': '填寫發票標題',
  'Please enter ABN': '請輸入ABN',
  'Fill in your ABN': '填寫您的 ABN',
  'Email address': '電子郵件地址',
  'Please enter Email address': '請輸入電子郵件地址',
  'Fill in your email address': '填寫您的電子郵件地址',
  'City': '城市',
  'Please enter City': '請輸入城市',
  'Fill in your city': '填寫您所在的城市',
  'Detailed address': '詳細地址',
  'Please enter Detailed address': '請輸入詳細地址',
  'Fill in your Detailed address': '填寫您的詳細地址',
  'Email address format is incorrect': '電子郵件地址格式不正確',
  'Your billing request has been submitted': '您的帳單請求已提交',
  'Please check it.': '請檢查一下。',
  'Welcome to Yourbox Pay Later!': '歡迎使用 Yourbox 稍後付款！',
};
