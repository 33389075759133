export default {
  '国家或地区': '国家或地区',
  '西班牙': '西班牙',
  '澳大利亚': '澳大利亚',
  '英国': '英国',
  '选择语言': '选择语言',
  'ABN': 'ABN',
  'Download the Yourbox App': '下载Yourbox APP',
  '会话已超时，可返回上一页重新进入': '会话已超时，可返回上一页重新进入',
  'Scan with mobile device to download the YOURBOX APP': '扫码下载Yourbox App',
  'Global Trendsetter': '全球潮流引领者',
  'Quality Beyond Compare': '无与伦比的品质',
  'Value for Money': '物有所值',
  'Technology Innovation': '技术创新',
  'We consistently monitor global trends, ensuring our offerings remain avant-garde.': '我们持续关注全球趋势，确保我们的产品保持前卫。',
  'We present you with the latest and most distinctive products, ensuring you remain at the vanguard of both fashion and innovation.': '我们为您提供最新、最独特的产品，确保您始终处于时尚和创新的先锋地位。',
  'Light Luxury Essence': '轻奢精华',
  'We ardently believe that high-caliber products imbue an inherent touch of light luxury into everyday life.': '我们坚信，高品质的产品能为日常生活注入与生俱来的轻奢气息。',
  'Each Yourbox product is meticulously designed to emanate an aura of opulence and sophistication.': '每件 Yourbox 产品都经过精心设计，散发出奢华和精致的气息。',
  'Affordable Elegance': '经济实惠的优雅',
  'Our commitment extends beyond mere high quality; it encompasses accessibility for all.': '我们的承诺不仅仅是高质量； 它涵盖了所有人的无障碍性。',
  'We meticulously uphold prices that are not just budget-friendly but afford you the opportunity to seamlessly embrace an upscale lifestyle.': '我们精心维护的价格不仅适合预算，而且为您提供无缝拥抱高档生活方式的机会。',
  'FASTHORSE LME, SOCIEDAD LIMITADA': 'FASTHORSE LME, SOCIEDAD LIMITADA',
  'LAST MILE EXPRESS PTY LTD': 'LAST MILE EXPRESS PTY LTD',
  'LAST MILE EXPRESS LIMITED': 'LAST MILE EXPRESS LIMITED',
  'Choosing Yourbox means choosing global excellence, experiencing upscale technology products, and enjoying affordable elegance. Thank you for becoming a part of our brand!': '选择Yourbox意味着选择全球卓越，体验高端技术产品，享受实惠的优雅。 感谢您成为我们品牌的一部分！',
  'or its affiliates Contact us at': '或其附属公司 联系我们：',
  'Welcome to Yourbox': '欢迎来到Yourbox',
  'What sets us apart?': '我们有何不同之处？',
  'afforable price': '实惠的价格',
  'We take pride in our unique global selection of trending products while maintaining a more': '我们为我们独特的全球流行产品选择而感到自豪，同时保持更',
  'Tax Invoice': '税务发票',
  'Invoice/Order Number': '发票/订单号',
  'Delivery Date': '配送日期',
  'Delivery Time': '配送时间',
  'Supplied': '商品明细',
  'Line': '序号',
  'Description': '商品描述',
  'Quantity': '数量',
  'Price': '单价',
  'Amount': '总价',
  'Item subtotal': '商品总价',
  'Delivery fee': '配送费',
  'Coupons': '优惠金额',
  'Subtotal': '合计',
  'GST Included In Subtotal': '增值税包含在合计中',
  'Indicates GST applicable items': '带*为含税商品',
  'Discount': '折扣金额',
  'Company Name': '公司名称',
  'Store activities': '门店活动',
  'Jan': '一月',
  'Feb': '二月',
  'Mar': '三月',
  'Apr': '四月',
  'May': '五月',
  'Jun': '六月',
  'July': '七月',
  'Aug': '八月',
  'Sep': '九月',
  'Oct': '十月',
  'Nov': '十一月',
  'Dec': '十二月',
  'Step': '步骤',
  'Download our Yourbox App': '下载我们的Yourbox App',
  'Register an account': '注册一个账号',
  'Yourbox App scans code to pay': '使用Yourbox App扫码',
  'Welcome to our store': 'Yourbox欢迎您',
  'Yourbox Pay': 'Yourbox Pay',
  'Pay with Yourbox': '使用Yourbox支付',
  'At Yourbox, we believe everyone deserves access to the latest electronic gadgets, coolest toys, and essential home appliances without breaking the bank. That\'s why we offer a curated selection of trendy products at incredibly affordable prices.': '在 Yourbox，我们相信每个人都应该在不花太多钱的情况下获得最新的电子产品、最酷的玩具和基本的家用电器。 这就是为什么我们以极其实惠的价格提供精选的时尚产品。',
  'Welcome to Yourbox Pay Later': '欢迎使用 Yourbox Pay Later',
  'Quality Guarantee': '质量保证',
  'Buy Now Pay Later': '先买后付',
  'Zero Interest rate': '零利率',
  'Affordable Prices': '付得起的价钱',
  'Our products are priced competitively, ensuring you get the best value for your money.': '我们的产品价格具有竞争力，确保您物有所值。',
  'Trendy Selection': '时尚精选',
  'Stay ahead of the curve with our constantly updated collection of electronics, toys, and home appliances.': '通过我们不断更新的电子产品、玩具和家用电器系列保持领先地位。',
  'Zero Interest': '零利息',
  'Say goodbye to hidden fees and extra charges. With zero interest, you can shop with confidence knowing exactly what you\'ll pay.': '告别隐藏费用和额外费用。 由于零利息，您可以放心购物，确切地知道您将支付什么费用。',
  'Shop with Confidence': '放心购物',
  'Don\'t let budget constraints hold you back from getting the products you desire.  Experience the convenience of buying now and paying later with Yourbox Pay Later.  Start browsing our collection now and enjoy the latest trends at unbeatable prices!': '不要让预算限制阻碍您获得所需的产品。 使用 Yourbox Pay Later 体验先购买后付款的便利。 立即开始浏览我们的产品，以无与伦比的价格享受最新趋势！',
  '接受': '接受',
  '拒绝': '拒绝',
  'You have already be ${name} contact': '你已成为${name}在Yourbox的联系人',
  'You have already reject to be ${name} contact': '你已拒绝成为${name}在Yourbox的联系人',
  '${name} invites you to be his contact': '${name}邀请你成为他的联系人',
  '确定要接受么？': '确定要接受么？',
  '确定要拒绝么？': '确定要拒绝么？',
  'Submit successfully': '提交成功',
  'Your invoice request has been submitted': '您的发票申请已提交',
  'successfully.': '成功',
  'The invoice will be sent to your email': '发票将发送至您的电子邮箱',
  'within 7 working days.': '7个工作日内。',
  'Please wait patiently and check your': '请耐心等待并检查您的',
  'email. Thank you!': '电子邮件。谢谢你！',
  'Issue an invoice': '开具发票',
  'Please fill in your invoice information accurately': '请准确填写您的发票信息',
  'and cannot be changed after confirmation': '确认后不可更改',
  'Invoice details': '发票明细',
  'Invoice title': '发票标题',
  'Please enter Invoice title': '请输入发票标题',
  'Fill in the Invoice title': '填写发票标题',
  'Please enter ABN': '请输入ABN',
  'Fill in your ABN': '填写您的 ABN',
  'Email address': '电子邮件地址',
  'Please enter Email address': '请输入电子邮件地址',
  'Fill in your email address': '填写您的电子邮件地址',
  'City': '城市',
  'Please enter City': '请输入城市',
  'Fill in your city': '填写您所在的城市',
  'Detailed address': '详细地址',
  'Please enter Detailed address': '请输入详细地址',
  'Fill in your Detailed address': '填写您的详细地址',
  'Email address format is incorrect': '电子邮件地址格式不正确',
  'Your billing request has been submitted': '您的帐单请求已提交',
  'Please check it.': '请检查一下。',
  'Welcome to Yourbox Pay Later!': '欢迎使用 Yourbox 稍后付款！',
};
