/* \
|*|
|*|  :: cookies.js ::
|*|
|*|  A complete cookies reader/writer framework with full unicode support.
|*|
|*|  https://developer.mozilla.org/en-US/docs/DOM/document.cookie
|*|
|*|  This framework is released under the GNU Public License, version 3 or later.
|*|  http://www.gnu.org/licenses/gpl-3.0-standalone.html
|*|
|*|  Syntaxes:
|*|
|*|  * docCookies.setItem(name, value[, end[, path[, domain[, secure]]]])
|*|  * docCookies.getItem(name)
|*|  * docCookies.removeItem(name[, path], domain)
|*|  * docCookies.hasItem(name)
|*|  * docCookies.keys()
|*|
\*/

// 描述
// 创建或覆盖一个cookie

// 参数
// name (必要)
// 要创建或覆盖的cookie的名字 (string)。
// value (必要)
// cookie的值 (string)。
// end (可选)
// 最大年龄的秒数 (一年为31536e3， 永不过期的cookie为Infinity) ，
// 或者过期时间的GMTString格式或Date对象;
// 如果没有定义则会在会话结束时过期 (number – 有限的或 Infinity – string, Date object or null)。
// path (可选)
// 例如 '/', '/mydir'。 如果没有定义，默认为当前文档位置的路径。
// (string or null)。路径必须为绝对路径（参见 RFC 2965）。关于如何在这个参数使用相对路径的方法请参见这段。
// domain (可选)
// 例如 'example.com'， '.example.com' (包括所有子域名), 'subdomain.example.com'。
// 如果没有定义，默认为当前文档位置的路径的域名部分 (string或null)。
// secure (可选)
// cookie只会被https传输 (boolean或null)。

const docCookies = {
  getItem(sKey: string): string | null {
    return (
      decodeURIComponent(document.cookie.replace(
        new RegExp(`(?:(?:^|.*;)\\s*${encodeURIComponent(sKey).replace(
          /[-.+*]/g,
          '\\$&',
        )}\\s*\\=\\s*([^;]*).*$)|^.*$`),
        '$1',
      )) || null
    );
  },
  setItem(
    sKey: string,
    sValue: string,
    vEnd?: number | string | Date | null,
    sPath?: string | null,
    sDomain?: string | null,
    bSecure?: boolean | null,
  ): boolean {
    if (!sKey || /^(?:expires|max-age|path|domain|secure)$/i.test(sKey)) {
      return false;
    }

    let sExpires = '';
    if (vEnd) {
      switch (vEnd.constructor) {
        case Number:
          sExpires
            = vEnd === Infinity ? '; expires=Fri, 31 Dec 9999 23:59:59 GMT' : `; max-age=${vEnd}`;
          break;
        case String:
          sExpires = `; expires=${vEnd}`;
          break;
        case Date:
          sExpires = `; expires=${(vEnd as Date).toUTCString()}`;
          break;
        default: {
          break;
        }
      }
    }
    const domain = sDomain || window.location.host.split('.').slice(-2)
      .join('.')
      .split(':')[0];

    document.cookie = `${encodeURIComponent(sKey)}=${encodeURIComponent(sValue)}${sExpires}${
      domain ? `; domain=${domain}` : ''
    }; path=${sPath || '/'}${bSecure ? '; secure' : ''}`;

    return true;
  },
  removeItem(sKey: string, sPath?: string | null, sDomain?: string | null): boolean {
    if (!sKey || !this.hasItem(sKey)) {
      return false;
    }
    document.cookie = `${encodeURIComponent(sKey)}=; expires=Thu, 01 Jan 1970 00:00:00 GMT${
      sDomain ? `; domain=${sDomain}` : ''
    }; path=${sPath || '/'}`;
    return true;
  },
  hasItem(sKey: string): boolean {
    return new RegExp(`(?:^|;\\s*)${encodeURIComponent(sKey).replace(/[-.+*]/g, '\\$&')}\\s*\\=`).test(document.cookie);
  },
  /* optional method: you can safely remove it! */
  keys(): string[] {
    const aKeys = document.cookie
      .replace(/((?:^|\s*;)[^=]+)(?=;|$)|^\s*|\s*(?:=[^;]*)?(?:\1|$)/g, '')
      .split(/\s*(?:=[^;]*)?;\s*/);
    for (let nIdx = 0; nIdx < aKeys.length; nIdx += 1) {
      aKeys[nIdx] = decodeURIComponent(aKeys[nIdx]);
    }
    return aKeys;
  },
};

export default docCookies;
