// @ts-nocheck
import { ApplyPluginsType, dynamic } from '/usr/src/app/node_modules/.pnpm/@umijs+runtime@3.2.17_react@16.14.0/node_modules/@umijs/runtime';
import { plugin } from './plugin';
import LoadingComponent from '@/components/loading';

export function getRoutes() {
  const routes = [
  {
    "path": "/activityExplanationText",
    "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__docs__desc__activityExplanationText' */'@/pages/docs/desc/activityExplanationText'), loading: LoadingComponent}),
    "title": "Activity Explanation Text",
    "exact": true
  },
  {
    "path": "/privacyPolicy",
    "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__docs__privacyPolicy' */'@/pages/docs/privacyPolicy'), loading: LoadingComponent}),
    "title": "PRIVACY POLICY",
    "exact": true
  },
  {
    "path": "/termsOfService",
    "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__docs__termsOfService' */'@/pages/docs/termsOfService'), loading: LoadingComponent}),
    "title": "INDEPENDENT CONTRACTOR TERMS OF SERVICE",
    "exact": true
  },
  {
    "path": "/yourboxStore/privacyPolicy",
    "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__docs__yourboxStore__privacyPolicy' */'@/pages/docs/yourboxStore/privacyPolicy'), loading: LoadingComponent}),
    "title": "PRIVACY POLICY",
    "exact": true
  },
  {
    "path": "/yourboxStore/termsOfService",
    "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__docs__yourboxStore__termsOfService' */'@/pages/docs/yourboxStore/termsOfService'), loading: LoadingComponent}),
    "title": "INDEPENDENT CONTRACTOR TERMS OF SERVICE",
    "exact": true
  },
  {
    "path": "/d/termsOfService",
    "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__docs__driver__termsOfService' */'@/pages/docs/driver/termsOfService'), loading: LoadingComponent}),
    "title": "PRIVACY POLICY",
    "exact": true
  },
  {
    "path": "/preview",
    "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__pdfPreview' */'@/pages/pdfPreview'), loading: LoadingComponent}),
    "title": " ",
    "exact": true
  },
  {
    "path": "/privacyPolicyEs",
    "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__docs__privacyPolicyEs' */'@/pages/docs/privacyPolicyEs'), loading: LoadingComponent}),
    "title": "PRIVACY POLICY",
    "exact": true
  },
  {
    "path": "/termsOfServiceEs",
    "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__docs__termsOfServiceEs' */'@/pages/docs/termsOfServiceEs'), loading: LoadingComponent}),
    "title": "INDEPENDENT CONTRACTOR TERMS OF SERVICE",
    "exact": true
  },
  {
    "path": "/privacyPolicyUk",
    "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__docs__privacyPolicyUk' */'@/pages/docs/privacyPolicyUk'), loading: LoadingComponent}),
    "title": "PRIVACY POLICY",
    "exact": true
  },
  {
    "path": "/termsOfServiceUk",
    "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__docs__termsOfServiceUk' */'@/pages/docs/termsOfServiceUk'), loading: LoadingComponent}),
    "title": "INDEPENDENT CONTRACTOR TERMS OF SERVICE",
    "exact": true
  },
  {
    "path": "/installmentAgreement",
    "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__docs__desc__installmentAgreement' */'@/pages/docs/desc/installmentAgreement'), loading: LoadingComponent}),
    "title": "COMPRA AHORA Y PAGA DESPUÉS",
    "exact": true
  },
  {
    "path": "/invoice",
    "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__invoice' */'@/pages/invoice'), loading: LoadingComponent}),
    "title": "Invoice Preview",
    "exact": true
  },
  {
    "path": "/merchant/login",
    "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__merchant__login' */'@/pages/merchant/login'), loading: LoadingComponent}),
    "title": "yourbox",
    "exact": true
  },
  {
    "path": "/merchant/register",
    "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__merchant__register' */'@/pages/merchant/register'), loading: LoadingComponent}),
    "title": "yourbox",
    "exact": true
  },
  {
    "path": "/merchant/verificationCode",
    "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__merchant__verificationCode' */'@/pages/merchant/verificationCode'), loading: LoadingComponent}),
    "title": "yourbox",
    "exact": true
  },
  {
    "path": "/merchant/setPassword",
    "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__merchant__setPassword' */'@/pages/merchant/setPassword'), loading: LoadingComponent}),
    "title": "yourbox",
    "exact": true
  },
  {
    "path": "/merchant/userInformation",
    "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__merchant__userInformation' */'@/pages/merchant/userInformation'), loading: LoadingComponent}),
    "title": "yourbox",
    "exact": true
  },
  {
    "path": "/merchant/loginResult",
    "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__merchant__loginResult' */'@/pages/merchant/loginResult'), loading: LoadingComponent}),
    "title": "yourbox",
    "exact": true
  },
  {
    "path": "/merchant/auditIndex",
    "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__merchant__auditIndex' */'@/pages/merchant/auditIndex'), loading: LoadingComponent}),
    "title": "yourbox",
    "exact": true
  },
  {
    "path": "/merchant/auditResult",
    "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__merchant__auditResult' */'@/pages/merchant/auditResult'), loading: LoadingComponent}),
    "title": "yourbox",
    "exact": true
  },
  {
    "path": "/merchant/settings",
    "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__merchant__settings' */'@/pages/merchant/settings'), loading: LoadingComponent}),
    "title": "Settings",
    "exact": true
  },
  {
    "path": "/merchant/redeemResult",
    "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__merchant__redeemResult' */'@/pages/merchant/redeemResult'), loading: LoadingComponent}),
    "title": "yourbox",
    "exact": true
  },
  {
    "path": "/merchant/dataPage",
    "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__merchant__dataPage' */'@/pages/merchant/dataPage'), loading: LoadingComponent}),
    "title": "yourbox",
    "exact": true
  },
  {
    "path": "/merchant/scanPage",
    "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__merchant__scanPage' */'@/pages/merchant/scanPage'), loading: LoadingComponent}),
    "title": "yourbox",
    "exact": true
  },
  {
    "path": "/partTime/login",
    "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__partTime__login' */'@/pages/partTime/login'), loading: LoadingComponent}),
    "title": "yourbox",
    "exact": true
  },
  {
    "path": "/partTime/register",
    "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__partTime__register' */'@/pages/partTime/register'), loading: LoadingComponent}),
    "title": "yourbox",
    "exact": true
  },
  {
    "path": "/partTime/verificationCode",
    "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__partTime__verificationCode' */'@/pages/partTime/verificationCode'), loading: LoadingComponent}),
    "title": "yourbox",
    "exact": true
  },
  {
    "path": "/partTime/setPassword",
    "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__partTime__setPassword' */'@/pages/partTime/setPassword'), loading: LoadingComponent}),
    "title": "yourbox",
    "exact": true
  },
  {
    "path": "/partTime/userInformation",
    "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__partTime__userInformation' */'@/pages/partTime/userInformation'), loading: LoadingComponent}),
    "title": "yourbox",
    "exact": true
  },
  {
    "path": "/partTime/loginResult",
    "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__partTime__loginResult' */'@/pages/partTime/loginResult'), loading: LoadingComponent}),
    "title": "yourbox",
    "exact": true
  },
  {
    "path": "/partTime/auditIndex",
    "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__partTime__auditIndex' */'@/pages/partTime/auditIndex'), loading: LoadingComponent}),
    "title": "yourbox",
    "exact": true
  },
  {
    "path": "/partTime/settings",
    "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__partTime__settings' */'@/pages/partTime/settings'), loading: LoadingComponent}),
    "title": "Settings",
    "exact": true
  },
  {
    "path": "/partTime/earnings",
    "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__partTime__earnings' */'@/pages/partTime/earnings'), loading: LoadingComponent}),
    "title": "Settings",
    "exact": true
  },
  {
    "path": "/partTime/earnDetail",
    "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__partTime__earnDetail' */'@/pages/partTime/earnDetail'), loading: LoadingComponent}),
    "title": "Settings",
    "exact": true
  },
  {
    "path": "/partTime/accountInfo",
    "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__partTime__accountInfo' */'@/pages/partTime/accountInfo'), loading: LoadingComponent}),
    "title": "Settings",
    "exact": true
  },
  {
    "path": "/",
    "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__pc__home' */'@/pages/pc/home'), loading: LoadingComponent}),
    "title": "yourbox",
    "exact": true
  },
  {
    "path": "/invite",
    "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__invite' */'@/pages/invite'), loading: LoadingComponent}),
    "title": "invite",
    "exact": true
  },
  {
    "path": "/commodityCommentShare",
    "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__commodityCommentShare' */'@/pages/commodityCommentShare'), loading: LoadingComponent}),
    "title": "commodityCommentShare",
    "exact": true
  },
  {
    "path": "/transfer",
    "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__transfer' */'@/pages/transfer'), loading: LoadingComponent}),
    "title": "transfer",
    "exact": true
  },
  {
    "path": "/electronic-receipt",
    "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__electronicReceipt' */'@/pages/electronicReceipt'), loading: LoadingComponent}),
    "title": "electronicReceipt",
    "exact": true
  },
  {
    "path": "/h5/home",
    "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__mobileHome' */'@/pages/mobileHome'), loading: LoadingComponent}),
    "title": "yourbox",
    "exact": true
  },
  {
    "path": "/store/landing",
    "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__storeLanding' */'@/pages/storeLanding'), loading: LoadingComponent}),
    "title": "store",
    "exact": true
  },
  {
    "path": "/invite-contact",
    "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__invite__contact' */'@/pages/invite/contact'), loading: LoadingComponent}),
    "title": "invite",
    "exact": true
  },
  {
    "path": "/invite-result",
    "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__invite__contact__result' */'@/pages/invite/contact/result'), loading: LoadingComponent}),
    "title": "result",
    "exact": true
  },
  {
    "path": "/lssueInvoice",
    "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__lssueInvoice' */'@/pages/lssueInvoice'), loading: LoadingComponent}),
    "title": "lssueAnInvoice",
    "exact": true
  },
  {
    "path": "/*",
    "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__404' */'@/pages/404'), loading: LoadingComponent}),
    "title": "NOT FOUND",
    "exact": true
  }
];

  // allow user to extend routes
  plugin.applyPlugins({
    key: 'patchRoutes',
    type: ApplyPluginsType.event,
    args: { routes },
  });

  return routes;
}
