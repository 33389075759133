export default {
  '国家或地区': 'País o región',
  '西班牙': 'España',
  '澳大利亚': 'australiano',
  '英国': 'Reino Unido',
  '选择语言': 'Seleccione su idioma',
  'ABN': 'N.I.F/C.I.F',
  'Download the Yourbox App': 'Descarga la aplicación Yourbox',
  '会话已超时，可返回上一页重新进入': 'La sesión ha expirado, puede volver a la página anterior',
  'Scan with mobile device to download the YOURBOX APP': 'Escanea con dispositivo móvil para descargar la APP YOURBOX',
  'Global Trendsetter': 'Creador de tendencias globales',
  'Quality Beyond Compare': 'Calidad incomparable',
  'Value for Money': 'Valor por dinero',
  'Technology Innovation': 'Innovación tecnológica',
  'We consistently monitor global trends, ensuring our offerings remain avant-garde.': 'Seguimos constantemente las tendencias globales, garantizando que nuestras ofertas sigan siendo vanguardistas.',
  'We present you with the latest and most distinctive products, ensuring you remain at the vanguard of both fashion and innovation.': 'Le presentamos los productos más modernos y distintivos, garantizando que permanezca a la vanguardia tanto de la moda como de la innovación.',
  'Light Luxury Essence': 'Esencia ligera de lujo',
  'We ardently believe that high-caliber products imbue an inherent touch of light luxury into everyday life.': 'Estamos firmemente convencidos de que los productos de alto nivel aportan un ligero toque de lujo a la vida cotidiana.',
  'Each Yourbox product is meticulously designed to emanate an aura of opulence and sophistication.': 'Cada producto Yourbox está meticulosamente diseñado para emanar un aroma de opulencia y sofisticación.',
  'Affordable Elegance': 'Elegancia asequible',
  'Our commitment extends beyond mere high quality; it encompasses accessibility for all.': 'Nuestro compromiso va más allá de la mera alta calidad; incluye la accesibilidad para todos.',
  'We meticulously uphold prices that are not just budget-friendly but afford you the opportunity to seamlessly embrace an upscale lifestyle.': 'Mantenemos meticulosamente unos precios que no sólo son asequibles, sino que le ofrecen la oportunidad de disfrutar de un estilo de vida exclusivo.',
  'FASTHORSE LME, SOCIEDAD LIMITADA': 'FASTHORSE LME, SOCIEDAD LIMITADA',
  'LAST MILE EXPRESS PTY LTD': 'LAST MILE EXPRESS PTY LTD',
  'LAST MILE EXPRESS LIMITED': 'LAST MILE EXPRESS LIMITED',
  'Choosing Yourbox means choosing global excellence, experiencing upscale technology products, and enjoying affordable elegance. Thank you for becoming a part of our brand!': 'Elegir Yourbox significa elegir la excelencia global, experimentar productos tecnológicos de alta calidad y disfrutar de una elegancia asequible. Gracias por formar parte de nuestra marca.',
  'or its affiliates Contact us at': 'O sus afiliados Contáctenos a',
  'Welcome to Yourbox': 'Bienvenido a Yourbox',
  'What sets us apart?': '¿Qué es lo que nos diferencia?',
  'afforable price': 'Precio pagable',
  'We take pride in our unique global selection of trending products while maintaining a more': 'Estamos orgullosos de nuestra selección global única de productos de tendencia y al mismo tiempo mantenemos una más',
  'Tax Invoice': 'Factura de impuestos',
  'Invoice/Order Number': 'Número de factura/pedido',
  'Delivery Date': 'Fecha de entrega',
  'Delivery Time': 'Plazo de entrega',
  'Supplied': 'suministrado',
  'Line': 'línea',
  'Description': 'descripción',
  'Quantity': 'La cantidad',
  'Price': 'precio',
  'Amount': 'importe',
  'Item subtotal': 'Partida subtotal',
  'Delivery fee': 'Gastos de envío',
  'Coupons': 'cupones',
  'Subtotal': 'Subtotal',
  'GST Included In Subtotal': 'Incluido en el Subtotal',
  'Indicates GST applicable items': 'Indica los elementos aplicables al IVA',
  'Discount': 'Descuento descuento',
  'Company Name': 'Nombre de la empresa',
  'Store activities': 'Actividades de la tienda',
  'Jan': 'de enero de',
  'Feb': 'de febrero de',
  'Mar': 'de marzo de',
  'Apr': 'de abril de',
  'May': 'de mayo de',
  'Jun': 'de junio de',
  'July': 'de julio de',
  'Aug': 'de agosto de',
  'Sep': 'de septiembre de',
  'Oct': 'de octubre de',
  'Nov': 'de noviembre de',
  'Dec': 'de diciembre de',
  'Step': 'paso',
  'Download our Yourbox App': 'Descarga nuestra aplicación Yourbox',
  'Register an account': 'registrar una cuenta',
  'Yourbox App scans code to pay': 'Escanea el código usando la aplicación Yourbox',
  'Welcome to our store': 'Yourbox te da la bienvenida',
  'Yourbox Pay': 'Tu caja de pago',
  'Pay with Yourbox': 'Paga con Yourbox',
  'At Yourbox, we believe everyone deserves access to the latest electronic gadgets, coolest toys, and essential home appliances without breaking the bank. That\'s why we offer a curated selection of trendy products at incredibly affordable prices.': 'En Yourbox, creemos que todos merecen acceso a los últimos gadgets electrónicos, los juguetes más geniales y los electrodomésticos esenciales sin que se te vacíe tu cuenta bancaria. Por eso ofrecemos una selección de productos de moda a precios increíblemente asequibles.',
  'Welcome to Yourbox Pay Later': 'Bienvenido a Yourbox Paga Después',
  'Quality Guarantee': 'Garantía de Calidad',
  'Buy Now Pay Later': 'Compra Ahora Paga Después',
  'Zero Interest rate': 'Tasa de Interés Cero',
  'Affordable Prices': 'Precios Asequibles',
  'Our products are priced competitively, ensuring you get the best value for your money.': 'Nuestros productos tienen precios competitivos, asegurando que obtengas el mejor valor y calidad por tu dinero.',
  'Trendy Selection': 'Selección de Moda',
  'Stay ahead of the curve with our constantly updated collection of electronics, toys, and home appliances.': 'Mantente a la vanguardia con nuestra colección constantemente actualizada de productos electrónicos, juguetes y electrodomésticos.',
  'Zero Interest': 'Cero Intereses',
  'Say goodbye to hidden fees and extra charges. With zero interest, you can shop with confidence knowing exactly what you\'ll pay.': 'Di adiós a las tarifas ocultas y cargos extra. Con cero intereses, puedes comprar con confianza sabiendo exactamente lo que pagarás.',
  'Shop with Confidence': 'Compra con Confianza',
  'Don\'t let budget constraints hold you back from getting the products you desire.  Experience the convenience of buying now and paying later with Yourbox Pay Later.  Start browsing our collection now and enjoy the latest trends at unbeatable prices!': 'No dejes que las limitaciones presupuestarias te impidan conseguir los productos que deseas. Experimenta la conveniencia de comprar ahora y pagar después con Yourbox Paga Después. ¡Comienza a explorar nuestra colección ahora y disfruta de las últimas tendencias a precios inmejorables!',
  '接受': 'Acepto',
  '拒绝': 'Rechazar',
  'You have already be ${name} contact': 'Te has convertido en el contacto de ${name} en Yourbox',
  'You have already reject to be ${name} contact': 'Has rechazado ser el contacto de ${name} en Yourbox',
  '${name} invites you to be his contact': '${name} te invita a ser su contacto',
  '确定要接受么？': '¿Estás seguro de que quieres aceptarlo?',
  '确定要拒绝么？': '¿Estás seguro de que quieres negarte?',
  'Submit successfully': 'Enviado satisfactoriamente',
  'Your invoice request has been submitted': 'Su solicitud de factura ha sido enviada',
  'successfully.': 'éxito',
  'The invoice will be sent to your email': 'La factura se enviará a su correo electrónico.',
  'within 7 working days.': 'En un plazo de 7 días hábiles.',
  'Please wait patiently and check your': 'correo electrónico. ¡Gracias!',
  'email. Thank you!': 'correo electrónico. ¡Gracias!',
  'Issue an invoice': 'Emitir una factura',
  'Please fill in your invoice information accurately': 'Por favor complete la información de su factura con precisión',
  'and cannot be changed after confirmation': 'No se puede cambiar después de la confirmación.',
  'Invoice details': 'Detalles de la factura',
  'Invoice title': 'Título de la factura',
  'Please enter Invoice title': 'Por favor ingrese el título de la factura',
  'Fill in the Invoice title': 'Rellene el título de la factura',
  'Please enter ABN': 'Por favor ingresa N.I.F/C.I.F',
  'Fill in your ABN': 'Complete su N.I.F/C.I.F',
  'Email address': 'dirección de correo electrónico',
  'Please enter Email address': 'Por favor ingrese la dirección de correo electrónico',
  'Fill in your email address': 'Introduzca su dirección de correo electrónico',
  'City': 'Ciudad',
  'Please enter City': 'Por favor ingresa la ciudad',
  'Fill in your city': 'Rellene su ciudad',
  'Detailed address': 'DIRECCIÓN',
  'Please enter Detailed address': 'Por favor ingrese la dirección detallada',
  'Fill in your Detailed address': 'Complete su dirección detallada',
  'Email address format is incorrect': 'El formato de la dirección de correo electrónico es incorrecto.',
  'Your billing request has been submitted': 'Su solicitud de facturación ha sido enviada',
  'Please check it.': 'Compruébelo por favor.',
  'Welcome to Yourbox Pay Later!': '¡Bienvenido a Yourbox Paga Más Tarde!',
};
