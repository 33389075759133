import type { MenuProps } from 'antd';
import React from 'react';
import { Dropdown, Space } from 'antd';
import { Dropdown as MBDropdown, CheckList } from 'antd-mobile';
import { CaretDownOutlined } from '@ant-design/icons';
import { getLocale, s, setLocale } from '@/locales';
import './index.scss';

const urlMap: Record<string, string> = {
  es: 'https://es.yourboxapp.com/',
  au: 'https://yourboxapp.com/',
  // gb: 'https://uk.yourboxapp.com/',
};

const menuData: MenuProps['items'] = [
  {
    label: <span className="country-title">{s('国家或地区')}</span>,
    key: 'select',
    disabled: true,
  },
  {
    key: 'es',
    label: s('西班牙'),
  },
  {
    key: 'au',
    label: s('澳大利亚'),
  },
  // {
  //   key: 'gb',
  //   label: s('英国'),
  // },
];

enum countryToLang {
  'es' = 'es-ES',
  'au' = 'en-US',
  'gb' = 'en-US',
}

const CountryDropdown: React.FC<{ isH5?: boolean }> = ({ isH5 = false }) => {
  const regionName = window.__multRegion;
  const onClick: MenuProps['onClick'] = (info) => {
    if (info.key === regionName) {
      return;
    }
    const url = `${urlMap[info.key]}?locale=${countryToLang[info.key]}`;
    window.open(url);
    // setLocale(countryToLang[info.key], true);
    // setTimeout(() => {
    //   window.location.reload();
    // }, 100);
  };

  const { label: countryNmae } = menuData.find((item) => item?.key === regionName) || {
    label: s('国家或地区'),
  };

  return isH5 ? (
    <MBDropdown>
      <MBDropdown.Item key="sorter" title={countryNmae}>
        <div style={{ padding: 12 }}>
          <CheckList
            defaultValue={[regionName]}
            onChange={(e) => {
              onClick({ key: e[0] });
            }}
          >
            {menuData.map((item) => {
              const { key, label } = item;
              return (
                <CheckList.Item value={key} key={key} readOnly={item?.disabled || false}>
                  {label}
                </CheckList.Item>
              );
            })}
          </CheckList>
        </div>
      </MBDropdown.Item>
    </MBDropdown>
  ) : (
    <Dropdown
      overlayClassName="region-dropdown"
      menu={{ items: menuData, selectedKeys: [regionName], onClick }}
    >
      <Space className="region-label">
        <span>{countryNmae}</span>
        <CaretDownOutlined className="region-icon" rev={undefined} />
      </Space>
    </Dropdown>
  );
};

export default CountryDropdown;
export { urlMap };
