// NOTE: 修改这个文件的某个值，可能需要修改该值对应的页面的 scss

enum Pages {
  /* Mobile */
  PrivacyPolicy = '/privacyPolicy',
  TermsOfService = '/termsOfService',
  DriverTermsOfService = '/d/termsOfService',
  PrivacyPolicyEs = '/privacyPolicyEs',
  TermsOfServiceEs = '/termsOfServiceEs',
  YourboxStorePrivacyPolicy = '/yourboxStore/privacyPolicy',
  YourboxStoreTermsOfService = '/yourboxStore/termsOfService',
  PrivacyPolicyUk = '/privacyPolicyUk',
  TermsOfServiceUk = '/termsOfServiceUk',
  InstallmentAgreement = '/installmentAgreement',
  PdfPreview = '/preview',

  // 地推商户页面 type:2
  YourLogin = '/merchant/login',
  YourRegister = '/merchant/register',
  VerificationCode = '/merchant/verificationCode',
  SetPassword = '/merchant/setPassword',
  UserInformation = '/merchant/userInformation',
  LoginResult = '/merchant/loginResult',
  AuditIndex = '/merchant/auditIndex',
  AuditResult = '/merchant/auditResult',
  Audit = '/merchant/audit',
  ScanPage = '/merchant/scanPage',
  Settings = '/merchant/settings',
  RedeemResult = '/merchant/redeemResult',
  DataPage = '/merchant/dataPage',
  // 地推兼职页面 type:3
  YourLogin3 = '/partTime/login',
  YourRegister3 = '/partTime/register',
  VerificationCode3 = '/partTime/verificationCode',
  SetPassword3 = '/partTime/setPassword',
  UserInformation3 = '/partTime/userInformation',
  LoginResult3 = '/partTime/loginResult',
  AuditIndex3 = '/partTime/auditIndex',
  AuditResult3 = '/partTime/auditResult',
  Audit3 = '/partTime/audit',
  ScanPage3 = '/partTime/scanPage',
  Settings3 = '/partTime/settings',
  RedeemResult3 = '/partTime/redeemResult',
  DataPage3 = '/partTime/dataPage',
  Earnings3 = '/partTime/earnings',
  EarnDetail3 = '/partTime/earnDetail',
  AccountInfo3 = '/partTime/accountInfo',
  Login = '/login',
  Invoice = '/invoice',

  /* PC */
  PCHome = '/',
  Invite = '/invite',
  CommodityCommentShare = '/commodityCommentShare',
  ActivityExplanationText = 'activityExplanationText',
  ElectronicReceipt = '/electronic-receipt',
  Transfer = '/transfer',
  MobileHome = '/h5/home',
  StoreLanding = '/store/landing',
  InviteContact = '/invite-contact',
  InviteResult = '/invite-result',
  LssueAnInvoice = '/lssueInvoice',
}

export default Pages;
