/**
 * @type {Object} scoutConfig - Scout 配置信息
 * @property {string} [scoutConfig.projectName=common_sensors] - BI 定义的项目名，默认使用公共埋点项目的项目名
 * @property {string} [appKey.appKey] - 仓储项目名，默认使用 pacakage.name
 * @property {Map<string, string>} pageNames.pageNames - key 是 pageCode，value 是 pageAbsolutePath
 * @global {string} __multRegion - 全局地区标识
 */

const PROJECT_NAME: Record<string, string> = {
  eu: 'lme_spain',
  us: 'lme_usa',
  nz: 'lme_nz',
  ca: 'lme_ca',
};

const DATA_API: Record<string, string> = {
  eu: 'https://dataapi-eu.lmexpress.com',
  us: 'https://dataapi-us.lmexpress.com',
  nz: 'https://dataapi.lmexpress.com',
  ca: 'https://dataapi-ca.lmexpress.com',
};
const isMerchant = () => {
  if (location.pathname.includes('merchant') || location.pathname.includes('partTime')) {
    return true;
  }
  return false;
};
const getProjectName = () => {
  const {
    location: { host },
    __multRegion,
  } = window;
  if (host === 'yourbox-test1-fe-yourbox-h5.quplusplus.net' || host.includes(':')) {
    return isMerchant() ? 'fresh_au_mer_test' : 'fresh_au_test';
  }
  return isMerchant() ? 'fresh_au_mer' : 'fresh_au';
};

const getServerDomain = () => {
  const {
    location: { host },
    __multRegion,
  } = window;
  return 'https://dataapi.fresh-au.yourboxapp.com';
};

const scoutConfig = {
  projectName: getProjectName(),
  appKey: 'yourbox-h5',
  serverDomain: getServerDomain(), // 埋点服务域名
  pageNames: {
    // '/': 'home',
    // '/faq': 'question',
    // '/pc/faq': 'question',
    '/merchant/register': 'register',
    '/merchant/verificationCode': 'tel_checking',
    '/merchant/setPassword': 'set_password',
    '/merchant/userInfomation': 'writing_message',
    '/merchant/loginResult': 'audit_waiting',
    '/merchant/scanPage': 'QR_scaning',
    '/merchant/auditResult': 'scaning_result',
    '/merchant/dataPagfe': 'date_detail',
    '/activityExplanationText': 'invited_policy',
  },
};

export default scoutConfig;
