export default {
  '国家或地区': 'Country or region',
  '西班牙': 'Spain',
  '澳大利亚': 'Australia',
  '英国': 'United Kingdom',
  '选择语言': 'Select language',
  'ABN': 'ABN',
  'Download the Yourbox App': 'Download the Yourbox App',
  '会话已超时，可返回上一页重新进入': 'The session has timed out. You can return to the previous page to re-enter',
  'Scan with mobile device to download the YOURBOX APP': 'Scan with mobile device to download the YOURBOX APP',
  'Global Trendsetter': 'Global Trendsetter',
  'Quality Beyond Compare': 'Quality Beyond Compare',
  'Value for Money': 'Value for Money',
  'Technology Innovation': 'Technology Innovation',
  'We consistently monitor global trends, ensuring our offerings remain avant-garde.': 'We consistently monitor global trends, ensuring our offerings remain avant-garde.',
  'We present you with the latest and most distinctive products, ensuring you remain at the vanguard of both fashion and innovation.': 'We present you with the latest and most distinctive products, ensuring you remain at the vanguard of both fashion and innovation.',
  'Light Luxury Essence': 'Light Luxury Essence',
  'We ardently believe that high-caliber products imbue an inherent touch of light luxury into everyday life.': 'We ardently believe that high-caliber products imbue an inherent touch of light luxury into everyday life.',
  'Each Yourbox product is meticulously designed to emanate an aura of opulence and sophistication.': 'Each Yourbox product is meticulously designed to emanate an aura of opulence and sophistication.',
  'Affordable Elegance': 'Affordable Elegance',
  'Our commitment extends beyond mere high quality; it encompasses accessibility for all.': 'Our commitment extends beyond mere high quality; it encompasses accessibility for all.',
  'We meticulously uphold prices that are not just budget-friendly but afford you the opportunity to seamlessly embrace an upscale lifestyle.': 'We meticulously uphold prices that are not just budget-friendly but afford you the opportunity to seamlessly embrace an upscale lifestyle.',
  'FASTHORSE LME, SOCIEDAD LIMITADA': 'FASTHORSE LME, SOCIEDAD LIMITADA',
  'LAST MILE EXPRESS PTY LTD': 'LAST MILE EXPRESS PTY LTD',
  'LAST MILE EXPRESS LIMITED': 'LAST MILE EXPRESS LIMITED',
  'Choosing Yourbox means choosing global excellence, experiencing upscale technology products, and enjoying affordable elegance. Thank you for becoming a part of our brand!': 'Choosing Yourbox means choosing global excellence, experiencing upscale technology products, and enjoying affordable elegance. Thank you for becoming a part of our brand!',
  'or its affiliates Contact us at': 'or its affiliates Contact us at',
  'Welcome to Yourbox': 'Welcome to Yourbox',
  'What sets us apart?': 'What sets us apart?',
  'afforable price': 'afforable price',
  'We take pride in our unique global selection of trending products while maintaining a more': 'We take pride in our unique global selection of trending products while maintaining a more',
  'Tax Invoice': 'Tax Invoice',
  'Invoice/Order Number': 'Invoice/Order Number',
  'Delivery Date': 'Delivery Date',
  'Delivery Time': 'Delivery Time',
  'Supplied': 'Supplied',
  'Line': 'Line',
  'Description': 'Description',
  'Quantity': 'Quantity',
  'Price': 'Price',
  'Amount': 'Amount',
  'Item subtotal': 'Item subtotal',
  'Delivery fee': 'Delivery fee',
  'Coupons': 'Coupons',
  'Subtotal': 'Subtotal',
  'GST Included In Subtotal': 'GST Included In Subtotal',
  'Indicates GST applicable items': 'Indicates GST applicable items',
  'Discount': 'Discount',
  'Company Name': 'Company Name',
  'Store activities': 'Store activities',
  'Jan': 'Jan',
  'Feb': 'Feb',
  'Mar': 'Mar',
  'Apr': 'Apr',
  'May': 'May',
  'Jun': 'Jun',
  'July': 'July',
  'Aug': 'Aug',
  'Sep': 'Sep',
  'Oct': 'Oct',
  'Nov': 'Nov',
  'Dec': 'Dec',
  'Step': 'Step',
  'Download our Yourbox App': 'Download our Yourbox App',
  'Register an account': 'Register an account',
  'Yourbox App scans code to pay': 'Yourbox App scans code to pay',
  'Welcome to our store': 'Welcome to our store',
  'Yourbox Pay': 'Yourbox Pay',
  'Pay with Yourbox': 'Pay with Yourbox',
  'At Yourbox, we believe everyone deserves access to the latest electronic gadgets, coolest toys, and essential home appliances without breaking the bank. That\'s why we offer a curated selection of trendy products at incredibly affordable prices.': 'At Yourbox, we believe everyone deserves access to the latest electronic gadgets, coolest toys, and essential home appliances without breaking the bank. That\'s why we offer a curated selection of trendy products at incredibly affordable prices.',
  'Welcome to Yourbox Pay Later': 'Welcome to Yourbox Pay Later',
  'Quality Guarantee': 'Quality Guarantee',
  'Buy Now Pay Later': 'Buy Now Pay Later',
  'Zero Interest rate': 'Zero Interest rate',
  'Affordable Prices': 'Affordable Prices',
  'Our products are priced competitively, ensuring you get the best value for your money.': 'Our products are priced competitively, ensuring you get the best value for your money.',
  'Trendy Selection': 'Trendy Selection',
  'Stay ahead of the curve with our constantly updated collection of electronics, toys, and home appliances.': 'Stay ahead of the curve with our constantly updated collection of electronics, toys, and home appliances.',
  'Zero Interest': 'Zero Interest',
  'Say goodbye to hidden fees and extra charges. With zero interest, you can shop with confidence knowing exactly what you\'ll pay.': 'Say goodbye to hidden fees and extra charges. With zero interest, you can shop with confidence knowing exactly what you\'ll pay.',
  'Shop with Confidence': 'Shop with Confidence',
  'Don\'t let budget constraints hold you back from getting the products you desire.  Experience the convenience of buying now and paying later with Yourbox Pay Later.  Start browsing our collection now and enjoy the latest trends at unbeatable prices!': 'Don\'t let budget constraints hold you back from getting the products you desire.  Experience the convenience of buying now and paying later with Yourbox Pay Later.  Start browsing our collection now and enjoy the latest trends at unbeatable prices!',
  '接受': 'Accept',
  '拒绝': 'Reject',
  'You have already be ${name} contact': 'You have become ${name}\'s contact on Yourbox',
  'You have already reject to be ${name} contact': 'You have declined to become ${name}\'s contact on Yourbox',
  '${name} invites you to be his contact': '${name} invites you to become his contact',
  '确定要接受么？': 'Are you sure you want to accept it?',
  '确定要拒绝么？': 'Are you sure you want to refuse?',
  'Submit successfully': 'Submit successfully',
  'Your invoice request has been submitted': 'Your invoice request has been submitted',
  'successfully.': 'successfully.',
  'The invoice will be sent to your email': 'The invoice will be sent to your email',
  'within 7 working days.': 'within 7 working days.',
  'Please wait patiently and check your': 'Please wait patiently and check your',
  'email. Thank you!': 'email. Thank you!',
  'Issue an invoice': 'Issue an invoice',
  'Please fill in your invoice information accurately': 'Please fill in your invoice information accurately',
  'and cannot be changed after confirmation': 'and cannot be changed after confirmation',
  'Invoice details': 'Invoice details',
  'Invoice title': 'Invoice title',
  'Please enter Invoice title': 'Please enter Invoice title',
  'Fill in the Invoice title': 'Fill in the Invoice title',
  'Please enter ABN': 'Please enter ABN',
  'Fill in your ABN': 'Fill in your ABN',
  'Email address': 'Email address',
  'Please enter Email address': 'Please enter Email address',
  'Fill in your email address': 'Fill in your email address',
  'City': 'City',
  'Please enter City': 'Please enter City',
  'Fill in your city': 'Fill in your city',
  'Detailed address': 'Detailed address',
  'Please enter Detailed address': 'Please enter Detailed address',
  'Fill in your Detailed address': 'Fill in your Detailed address',
  'Email address format is incorrect': 'Email address format is incorrect',
  'Your billing request has been submitted': 'Your billing request has been submitted',
  'Please check it.': 'Please check it.',
  'Welcome to Yourbox Pay Later!': 'Welcome to Yourbox Pay Later!',
};
