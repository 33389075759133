import dayjs from 'dayjs';
import { message } from 'antd';
import { urlMap } from '@/components/pc/countryDropdown';

export function noop(): void {
  // To nothing
}

/**
 * localStorage 封装
 */
export const LS = {
  set(key: string, data: any): void {
    localStorage.setItem(key, JSON.stringify(data));
  },
  get(key: string) {
    const item = localStorage.getItem(key);
    return item === null ? item : JSON.parse(item);
  },
  remove: localStorage.removeItem.bind(localStorage),
};

export function delay(second: number) {
  return new Promise((resolve) => {
    const timer = setTimeout(() => {
      clearTimeout(timer);
      resolve(true);
    }, second);
  });
}

export function getQuery(qs = ''): Record<string, string> {
  const queryStr = qs || window.location.search.slice(1);
  const query: Record<string, string> = {};
  queryStr.split('&').forEach((str: string) => {
    const [key, value] = str.split('=');
    if (!key && !value) {
      return;
    }
    query[key] = value;
  });
  return query;
}

export function replaceState(newQuery = {}): void {
  const oldSearch = window.location.search;
  const query = {
    ...getQuery(),
    ...(newQuery as Record<string, string>),
  };
  const newQueryStr = Object.keys(query)
    .filter((_) => _)
    .map((key) => `${key}=${query[key]}`)
    .join('&');
  const newUrl = oldSearch
    ? window.location.href.replace(oldSearch, `?${newQueryStr}`)
    : `${window.location.href}?${newQueryStr}`;
  window.history.replaceState({}, '', newUrl);
}

export function isPC(): boolean {
  const { pathname } = window.location;
  return pathname === '/' || pathname.startsWith('/pc/');
}

export function pcClass(className: string): string {
  return isPC() ? `${className} ${className}-pc` : className;
}

export function isIOS(): boolean {
  const u = window.navigator.userAgent;
  return u.includes('iPhone') || u.includes('Mac') || u.includes('iPad');
}

function tryRedirectCurCountry(country?: string): boolean {
  const dfCountry = country || LS.get('DEFAULT_COUNTRY');
  if (!dfCountry) {
    return false;
  }
  const newHost = urlMap[dfCountry];
  if (dfCountry === window.__multRegion) {
    return true;
  }
  if (!newHost) {
    return false;
  }
  const target = window.location.href.replace(window.location.origin, newHost.slice(0, -1));
  window.location.href = target;
  return true;
}

export function redirectToCurCounrty(): void {
  if (window.location.host !== 'fh.express' || window.location.pathname !== '/') {
    return;
  }
  const success = tryRedirectCurCountry();
  if (success) {
    return;
  }
  fetch('https://speed.cloudflare.com/meta')
    .then((response) => response.json())
    .then((data) => {
      const { country } = data || {};
      const countryMap = {
        // US: 'us',
        // USA: 'us',
        // CA: 'ca',
        // CAN: 'ca',
        AU: 'au',
        AUS: 'au',
        SP: 'es',
        SPA: 'es',
      };
      const curCountry = countryMap[country as 'es'] || 'es';
      LS.set('DEFAULT_COUNTRY', curCountry);
      tryRedirectCurCountry(curCountry);
    });
}

// 生成唯一设备 ID
export function generateDeviceId() {
  let deviceId = localStorage.getItem('deviceId');
  if (!deviceId) {
    deviceId = Date.now().toString(36) + Math.random().toString(36)
      .substring(2, 7);
    localStorage.setItem('deviceId', deviceId);
  }
  return deviceId;
}

/**
 * 日期转毫秒
 * @param day
 * @returns
 */
export function dayToMs(day: number) {
  return day * 24 * 60 * 60 * 1000;
}

// 国家货币符号
const CURRENCY_SYMBOLS: Record<string, string> = {
  us: 'USD', // 美国的货币符号缩写是USD（美元）。
  au: 'AUD', // 澳大利亚的货币符号缩写是AUD（澳元）。
  nz: 'CAD', // 加拿大的货币符号缩写是CAD（加元）。
  ca: 'NZD', // 新西兰的货币符号缩写是NZD（新西兰元）。
};

/**
 * 获取国家货币符号
 * @returns Currency Symbol
 */
export function regionCurrencySymbol(): string {
  return CURRENCY_SYMBOLS[window.__multRegion] || '';
}

// 其他国家后期上线，日期格式初始默认为DD/MM/YYYY - DD/MM/YYYY
const DATE_FORMATS: Record<string, string> = {
  us: 'MM/DD/YYYY', // 美国日期格式：MM/DD/YYYY - MM/DD/YYYY
  au: 'DD/MM/YYYY', // 澳大利亚、新西兰日期格式：DD/MM/YYYY - DD/MM/YYYY
  nz: 'DD/MM/YYYY', // 澳大利亚、新西兰日期格式：DD/MM/YYYY - DD/MM/YYYY
  ca: 'YYYY/MM/DD', // 加拿大日期格式：YYYY/MM/DD - YYYY/MM/DD/YYYY
};
/**
 * 根据不同国家展示不同提起格式
 * @param date
 * @returns
 */
export function formatRegionDate(date?: Date | string | number): string {
  return date ? dayjs(date).format(DATE_FORMATS[window.__multRegion || 'DD/MM/YYYY']) : '-';
}

// formats 下标对照枚举
export enum FORMAT_DATE {
  START, // 起始日期格式下标
  END, // 结束日期格式下标
  STANDARD, // 标准日期格式下标
  SORT_DATE, // 年月日
  TIME, // 标准时间格式下标
}

// 自定义转换格式
export const formats = [
  'YYYY-MM-DD 00:00:00',
  'YYYY-MM-DD 23:59:59',
  'YYYY-MM-DD HH:mm:ss',
  'YYYY-MM-DD',
  'HH:mm:ss',
];

/**
 * 格式化日期、时间
 * @param transformKeys 转化后的变量名称
 * @param formatIndex 针对单个转化格式下标
 * @returns
 */
export function transformDates(
  transformKeys: [string, string?],
  formatIndex: FORMAT_DATE = FORMAT_DATE.SORT_DATE,
): (date: Date[] | Date) => Record<string, string> {
  const convertDate: Record<string, string> = {};
  return (dateInfo?: Date[] | Date) => {
    if (dateInfo) {
      if (Array.isArray(dateInfo)) {
        dateInfo?.forEach((date: Date, i: number) => {
          const formatDate = dayjs(date).format(formats[formatIndex]);
          convertDate[transformKeys[i]!] = formatDate;
        });
      } else {
        const formatDate = dayjs(dateInfo).format(formats[formatIndex]);
        convertDate[transformKeys[0]] = formatDate;
      }
    }
    return convertDate;
  };
}

/**
 * 格式化金钱
 * @param num 金额
 */
export const formatMoney = (num: number): string => `${num}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',');

/**
 * 格式化包含小数点时保留多位小数，默认2位
 * @param number
 * @returns
 */
export const truncateDecimal = (number: number | string, decimals = 2): string => {
  const numberStr = `${number}`;
  if (/\./.test(numberStr)) {
    const decimalIndex = numberStr.indexOf('.');
    const decimalPart = numberStr.substring(decimalIndex + 1);
    const truncatedDecimalPart = decimalPart.padEnd(decimals, '0').substring(0, decimals);
    return numberStr.substring(0, decimalIndex + 1) + truncatedDecimalPart;
  }
  return numberStr || '0';
};

/** 国家区号 */
const areaCode = {
  au: '61',
  us: '1',
  ca: '1',
  nz: '64',
};

/** 获取当前国家区号 */
export const getCurrentAreaCode = (): string => areaCode[window.__multRegion] || '';

export const isAndroid = () => {
  const u = navigator.userAgent;
  return u.includes('Android') || u.includes('Linux');
};

/**
 * 区分安卓 ios跳转url
 */
export const openUrlWithSystem = (config: {
  /** 安卓应用市场 */
  androidAppUrl?: string;
  /** ios应用市场 */
  iosAppUrl?: string;
}) => {
  const { androidAppUrl, iosAppUrl } = config;
  const isAndroidApp = isAndroid();
  const isIosApp = isIOS();
  if (isAndroidApp && androidAppUrl) {
    window.location.href = androidAppUrl;
  }
  if (isIosApp && iosAppUrl) {
    window.location.href = iosAppUrl;
  }
};

/**
 * 在特定时间内唤醒app 未唤醒则跳转应用市场
 */
export const openApp = (config: {
  /** app 的scheme */
  scheme: string;
  /**
   * 单位毫秒
   * @default 3000
   *  */
  time?: number;
  /** 安卓应用市场 */
  androidAppUrl?: string;
  /** ios应用市场 */
  iosAppUrl?: string;
  /**
   * time 时间内未跳转 的回掉函数
   */
  noJumpCallback?: () => void;
}) => {
  const {
    scheme, time = 3000, androidAppUrl = '', iosAppUrl = '', noJumpCallback,
  } = config;

  const timer = setTimeout(() => {
    noJumpCallback?.();
    openUrlWithSystem({
      androidAppUrl,
      iosAppUrl,
    });
  }, time);

  window.addEventListener('visibilitychange', function oped() {
    if (document.hidden) {
      timer && clearTimeout(timer);
      window.removeEventListener('visibilitychange', oped);
    }
  });

  window.location.href = scheme;
};

function select(element: HTMLElement) {
  let selectedText;

  if (element.nodeName === 'SELECT') {
    element.focus();

    selectedText = element.value;
  } else if (element.nodeName === 'INPUT' || element.nodeName === 'TEXTAREA') {
    const isReadOnly = element.hasAttribute('readonly');

    if (!isReadOnly) {
      element.setAttribute('readonly', '');
    }

    element.select();
    element.setSelectionRange(0, element.value.length);

    if (!isReadOnly) {
      element.removeAttribute('readonly');
    }

    selectedText = element.value;
  } else {
    if (element.hasAttribute('contenteditable')) {
      element.focus();
    }

    const selection = window.getSelection();
    const range = document.createRange();

    range.selectNodeContents(element);
    selection.removeAllRanges();
    selection.addRange(range);

    selectedText = selection.toString();
  }

  return selectedText;
}

export const copyTextWithExecCommand = async(text: string) => {
  try {
    const isRTL = document.documentElement.getAttribute('dir') === 'rtl';
    const fakeElement = document.createElement('textarea');
    fakeElement.style.fontSize = '12pt';
    fakeElement.style.border = '0';
    fakeElement.style.padding = '0';
    fakeElement.style.margin = '0';
    fakeElement.style.position = 'absolute';
    fakeElement.style[isRTL ? 'right' : 'left'] = '-9999px';
    const yPosition = window.pageYOffset || document.documentElement.scrollTop;
    fakeElement.style.top = `${yPosition}px`;
    fakeElement.setAttribute('readonly', 'true');
    fakeElement.value = text;
    document.body.appendChild(fakeElement);
    const selectText = select(fakeElement);
    const oldCopyRes = document.execCommand('copy');
    console.log('oldCopyRes', oldCopyRes, selectText);
    document.body.removeChild(fakeElement);
    return oldCopyRes;
  } catch (error) {
    console.log('errorcopy', error);
  }
};

export async function copyTextWithClipboard(text: string) {
  try {
    const result = await navigator.permissions.query({
      name: 'clipboard-write' as PermissionName,
    });
    if (result.state === 'granted' || result.state === 'prompt') {
      navigator.clipboard.writeText(text);
      return true;
    }
    return false;
  } catch (error) {
    console.log('copyTextWithClipboarderror', error);
    return false;
  }
}
/**
 * 是否是生产环境
 */
export const isProd = process.env.NODE_ENV === 'production';
export const isDev = process.env.NODE_ENV === 'development';

/**
 * 环境
 */
export function getEnv(): 'dev' | 'testing' | 'prod' {
  if (process.env.BUILD_ENV === 'test') {
    return 'testing';
  }
  if (isProd) {
    return 'prod';
  }
  return 'dev';
}

// 是否是移动端
export function isMobileDevice(): boolean {
  return (
    typeof window.orientation !== 'undefined'
    || /iPhone|iPad|iPod|Android|Mobi/i.test(navigator.userAgent)
  );
}

// 进行等比缩放
export function scaleBody(width = 1440): void {
  const scaleRate = window.innerWidth / width;
  document.body.style.transform = `scale(${scaleRate})`;
  document.body.style.transformOrigin = 'left top';
}

/** pc快速适配移动端 */
export function scaleMobileBody(width = 1440): void {
  if (isMobileDevice()) {
    scaleBody(width);
  }
}

export function base64ToBlob(code: string) {
  const parts = code.split(';base64,');
  const contentType = parts[0].split(':')[1];
  const raw = window.atob(parts[1]);
  const rawLength = raw.length;
  const uInt8Array = new Uint8Array(rawLength);
  for (let i = 0; i < rawLength; ++i) {
    uInt8Array[i] = raw.charCodeAt(i);
  }
  return new Blob([uInt8Array], {
    type: contentType,
  });
}

export function downLoadImg(imgSrc: string, downloadName: string) {
  const image = new Image();
  // 解决跨域 Canvas 污染问题
  image.setAttribute('crossOrigin', 'anonymous');
  image.src = imgSrc;
  image.onload = function() {
    const canvas = document.createElement('canvas');
    canvas.width = image.width;
    canvas.height = image.height;
    const context = canvas.getContext('2d');
    if (!context) {
      message.error('not support download');
      return;
    }
    context.drawImage(image, 0, 0, image.width, image.height);
    const url = canvas.toDataURL('image/png'); // 得到图片的base64编码数据
    const a = document.createElement('a');
    const event = new MouseEvent('click');
    a.download = downloadName || `image-${new Date().getTime()}`;
    // 区别 ios 和 android 下载方式
    if (isAndroid()) {
      const blob = base64ToBlob(url);
      a.href = URL.createObjectURL(blob);
    } else {
      a.href = url;
    }
    a.dispatchEvent(event); // 触发a的单击事件
  };
}

interface File {
  dataUrl: string; // 转换后的文件地址
  blobFile: any; // blob文件
  fileImg: any; // file文件，一般用这个传给后端
}

// canvas生成的格式为base64，需要进行转化, base64->file
export const dataURLtoFile = (dataurl: any, fileName: string) => {
  const arr = dataurl.split(',');
  const mime = arr[0].match(/:(.*?);/)[1];

  const bstr = atob(arr[1]);
  let n = bstr.length;
  const u8arr = new Uint8Array(n);
  while (n--) {
    u8arr[n] = bstr.charCodeAt(n);
  }
  return new File([u8arr], fileName, { type: mime });
};

// canvas生成的格式为base64，需要进行转化, base64->blob
export const dataURLtoBlob = (dataurl: any) => {
  const arr = dataurl.split(',');
  const mime = arr[0].match(/:(.*?);/)[1];
  const bstr = atob(arr[1]);
  let n = bstr.length;
  const u8arr = new Uint8Array(n);
  while (n--) {
    u8arr[n] = bstr.charCodeAt(n);
  }
  return new Blob([u8arr], { type: mime });
};

// 图片压缩
/**
 * @压缩公共方法
 * @params file,fileName,imgW
 * @return base64地址，压缩后的文件，支持两种，file和 blob
 */
export const compressImg = (file: any, fileName = '', imgW: number | undefined): Promise<File> => new Promise((resolve, reject) => {
  const reader = new FileReader();
  // readAsDataURL 方法会读取指定的 Blob 或 File 对象。读取操作完成的时候，readyState 会变成已完成DONE，并触发 loadend (en-US) 事件，
  // 同时 result 属性将包含一个data:URL格式的字符串（base64编码）以表示所读取文件的内容。
  reader.readAsDataURL(file);
  reader.onload = () => {
    const img = new Image();
    img.src = reader.result as string;
    img.onload = () => {
      // 生成图片的宽高，宽度控制，高度等比例
      const w = imgW || img.width;
      const r = img.width / img.height;
      const h = imgW ? w / r : img.height;
      const canvas = document.createElement('canvas');
      // canvas对图片进行裁剪，这里设置为图片的原始尺寸
      canvas.width = w;
      canvas.height = h;
      const ctx: any = canvas.getContext('2d');
      // canvas中，png转jpg会变黑底，所以先给canvas铺一张白底
      ctx.fillStyle = '#fff';
      // fillRect()方法绘制一个填充了内容的矩形，这个矩形的开始点（左上点）在
      // (x, y) ，它的宽度和高度分别由width 和 height 确定，填充样式由当前的fillStyle 决定。
      ctx.fillRect(0, 0, imgW, canvas.height);
      // 绘制图像
      ctx.drawImage(img, 0, 0, w, h);

      // canvas转图片达到图片压缩效果
      // 返回一个包含图片展示的 data URI base64 在指定图片格式为 image/jpeg 或 image/webp的情况下，
      // 可以从 0 到 1 的区间内选择图片的质量。如果超出取值范围，将会使用默认值 0.92。其他参数会被忽略。
      const dataUrl = canvas.toDataURL('image/jpeg');

      // base64格式文件转成Blob文件格式
      const blobFile = dataURLtoBlob(dataUrl);
      // console.log('压缩后的图片：Blob文件----------');
      // console.log(blobFile);
      // base64格式文件转成file文件格式

      const fileImg = dataURLtoFile(dataUrl, fileName);
      // console.log('压缩后的图片：file文件----------');
      // console.log(fileImg);

      resolve({
        dataUrl, // 转换后的文件地址
        blobFile, // blob文件
        fileImg, // file文件，一般用这个传给后端
      });
    };
  };
});

export const getAppDownloadUrl = () => `${window.location.origin}/download`;

export const IsVideo = (url: string): boolean => {
  const videoExtensions: string[] = [
    'mp4',
    'avi',
    'rm',
    'rmvb',
    'wmv',
    'flv',
    '3gp',
    'mov',
    'mkv',
    'mpeg',
    'mpg',
    'ogg',
    'swf',
    'webm',
  ];
  const extension = url.split('?')[0].split('.').pop();
  return videoExtensions.includes(extension as string);
};
